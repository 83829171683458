<div fxFill>
    <ng-template #TooltipContent><div fxFlex="100" fxLayoutAlign="space-around"><mat-icon fontSet="fas" fxFlex="15" fxLayoutAlign="center center" class="icon-box g-text-white" fontIcon="fa-exclamation"></mat-icon><span><strong> Complete this field</strong></span></div></ng-template>
    <mat-tab-group class="tabs" mat-align-tabs="center" 
                   fxFlex="100" fxFlex.sm="98" dynamicHeight 
                   (selectedIndexChange)="selected.setValue($event)"
                   (selectedTabChange)="emitChangedTab($event)">
        <mat-tab label="Credit Card" >
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="center">
                <div fxFill fxLayoutAlign="center">
                    <div class="stripe-container" fxFlex="90" fxLayout="column" fxLayoutAlign="space-between center">
                        <form [formGroup]="creditCardForm" fxFlex="80" class="form wth">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-label fxFlex="40" class="label-form"><strong>Address Line 1:</strong></mat-label>
                                <mat-form-field [ngbTooltip]="TooltipContent" tooltipClass="tooltip-class" triggers="none" tooltipClass="tooltip-class" placement="bottom" [autoClose]="'outside'" #t1="ngbTooltip"
                                fxFlex="60" class="field-padding" appearance="outline">
                                    <input #add1 matInput required (change)="creditInfo($event)" [(ngModel)]="order.address1" placeholder="Street address, P.O. Box, etc" formControlName="address1"
                                    type="text">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-label fxFlex="40" class="label-form"><strong>Address Line 2:</strong></mat-label>
                                <mat-form-field fxFlex="60" class="field-padding" appearance="outline">
                                    <input #add2 matInput (change)="creditInfo($event)" [(ngModel)]="order.address2" placeholder="Apartment, suite, unit, building" formControlName="address2"
                                    type="text">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-label fxFlex="40"  class="label-form"><strong>City / Town:</strong></mat-label>
                                <mat-form-field fxFlex="60" class="field-padding" appearance="outline"
                                [ngbTooltip]="TooltipContent" tooltipClass="tooltip-class" triggers="none" tooltipClass="tooltip-class" placement="bottom" [autoClose]="'outside'" #t3="ngbTooltip">
                                    <input #city matInput required (change)="creditInfo($event)" [(ngModel)]="order.city" placeholder="City / Town" formControlName="city"
                                    type="text">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-label fxFlex="40" class="label-form"><strong>State:</strong></mat-label>
                                <mat-form-field fxFlex="60" class="field-padding" appearance="outline"
                                [ngbTooltip]="TooltipContent" triggers="none" tooltipClass="tooltip-class" placement="bottom" [autoClose]="'outside'" #t4="ngbTooltip">
                                    <input #state matInput required (change)="creditInfo($event)" maxlength="2" [(ngModel)]="order.state" placeholder="STATE" formControlName="state"
                                    type="text">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-label fxFlex="40" class="label-form"><strong>Zip Code:</strong></mat-label>
                                <mat-form-field fxFlex="60" class="field-padding" appearance="outline"
                                [ngbTooltip]="TooltipContent" triggers="none" tooltipClass="tooltip-class" placement="bottom" [autoClose]="'outside'" #t5="ngbTooltip">
                                    <input #zip matInput required (change)="creditInfo($event)" [(ngModel)]="order.zipCode" placeholder="Zip Code" formControlName="zipCode"
                                    type="text">
                                </mat-form-field>
                            </div>
                            <br>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-label fxFlex="40" class="label-form"><strong>Name on Credit Card: </strong></mat-label>
                                <mat-form-field [ngbTooltip]="TooltipContent" tooltipClass="tooltip-class" triggers="none" tooltipClass="tooltip-class" placement="bottom" [autoClose]="'outside'" #t7="ngbTooltip"
                                fxFlex="60" class="field-padding" appearance="outline">
                                    <input #name matInput required (change)="creditInfo($event)" placeholder="Name" formControlName="name" type="text">
                                </mat-form-field>
                            </div>
                        </form>
                        <div class="cc-container wth" fxLayout="column" fxLayoutAlign="center center">
                            <!--<ng-payment-card (formSaved)="creditInfo($event)"
                                [ccNumMissingTxt]="'Please provide card number'"
                                [cardExpiredTxt]="'Payment card has expired'"
                                [ccNumChecksumInvalidTxt]="'Invalid Card Number'">
                            </ng-payment-card>-->
                            <div fxLayout class="cc-name">
                                <ngx-stripe-card fxFlex="100"
                                [options]="cardOptions"
                                [elementsOptions]="elementsOptions">
                                </ngx-stripe-card>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Purchase Order">
            <div fxLayout="row" fxLayoutAlign="center">
                <div class="stripe-container hcontainer" fxFlex="70" fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxFlex="100" >
                        <div class="higher-label" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="isHigherPlan">
                            <span fxFlex="20" fxLayoutAlign="end"><strong>Pay by:</strong></span><span class="po-only-text" fxFlex="65">This Plan is Purchase Order Only</span>
                        </div>
                        <form [formGroup]="POForm" class="form wth" fxFlex="100">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-label fxFlex="30" class="label-form"><strong>PO Number</strong></mat-label>
                                <mat-form-field fxFlex="60" appearance="outline"
                                [ngbTooltip]="TooltipContent" triggers="none" tooltipClass="tooltip-class" placement="bottom" [autoClose]="'outside'" #t6="ngbTooltip">
                                    <input #PONumber  matInput required [(ngModel)]="purchaseOrderNumber" formControlName="PONumber"
                                    type="text" (change)="changePONumber()">
                                </mat-form-field>
                            </div>
                        </form>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <input style="display: none" #file type="file" name="image" accept="application/pdf"
                              (change)="uploadPDF(file.files)" />
                        </div>
                        <div class="modal-btn wth" fxLayout="row" fxLayoutAlign="start">
                            <mat-label fxFlex="30" class="label-form"><strong>Upload PDF</strong></mat-label>
                            <button mat-raised-button class="g-background-green g-text-white" (click)="file.click()" >
                                <span><mat-icon fontSet="fas" class="g-text-white" fontIcon="fa-cloud-upload-alt"></mat-icon> Upload {{POFile ? POFile.name: ''}}</span>
                            </button>
                        </div>
                        <div class="text-po" fxLayout="row" fxLayoutAlign="end">
                            <p fxFlex="80">Upon receipt of your PO we will contact you and give you access to the eCASLLS system. If there are any questions, please call Isela at (210) 832-2423</p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
