import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { log } from 'util';
import { AppConfig } from 'src/app/app.config';
import { UserModel } from '../models/user/user.model';
import { CustomerModel } from '../models/customer/customer.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralApiService {
  private url = AppConfig.settings.hosts.api;
 
  constructor(private httpClient: HttpClient) { }

  getUser(id: any) {
    return this.httpClient.get<any>(`${this.url}users/admin/${id}`)
  }

  registerUser(newUser) {
    var form_data = new FormData();
    for ( var key in newUser ) {
      form_data.append(key, newUser[key]);
    }
    return this.httpClient.post<any>(`${this.url}register`, form_data)
  }

  createUser(user: UserModel) {
    let newUser = user
    return this.httpClient.post<any>(`${this.url}users/${user.customerId}/admin`, newUser)
  }

  archiveUser(id):Observable<any>{
    return this.httpClient.put<any>(`${this.url}archived/users/${id}`, {})    
  }
  
  editUser(user: UserModel) {
    let editUser = user
    return this.httpClient.put<UserModel>(`${this.url}users/admin/${user.id}`, editUser)
  }
  
  editUserSingle(user: UserModel) {
    let editUser = user
    return this.httpClient.put<UserModel>(`${this.url}users/update/admin/${user.id}`, editUser)
  }

  deleteUser(userId){
    return this.httpClient.delete<any>(`${this.url}archived/users/${userId}`)
  }

  createCustomer(customer:CustomerModel){
    let addCustomer = {'customer': customer}
    return this.httpClient.post<any>(`${this.url}customers`, addCustomer)
  }

  checkEmail(email:any){
    return this.httpClient.get<any>(`${this.url}users?email=${email}`)
  }

  pageDate() {
    return this.httpClient.get<any>(`${this.url}pages/date`)
  }

  resendUserEmail(id, senderId) {
    return this.httpClient.get<any>(`${this.url}users/admin/resend-email/${id}`)
  }

  sendRecoverEmail(recoverEmail:any){
    return this.httpClient.post<any>(`${this.url}password_reset`, recoverEmail)
  }

  checkToken(token:string){
    return this.httpClient.get<any>(`${this.url}password_reset/${token}`);
  }

  resetPassword(token:string, body:any) {
    return this.httpClient.patch<any>(`${this.url}password_reset/${token}`, body);
  }
}
