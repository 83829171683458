<div class="container-modal" fxFill fxLayout="column">
    <div class="dialog-title">
        <div fxLayout="row" fxLayoutAlign="center center" class="edit-title">
          <div fxLayout="row" fxLayoutAlign="center center" fxFlex="95">
            <h2 mat-dialog-title class="dialog-title-text">ADD</h2>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" fxFlex="5">
            <mat-icon fontSet="fas" (click)="close()" fontIcon="fa-times"></mat-icon>
          </div>
        </div>
    </div>
    <mat-dialog-content class="dialog-container">

    <div class="dialog-body" *ngIf="editUserForm">
        <form [formGroup]="editUserForm">
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-label fxFlex="25">First Name*</mat-label>
            <mat-form-field fxFlex="60" appearance="outline">
                <input matInput required placeholder="First Name" [(ngModel)]="user.first_name" formControlName="first_name"
                type="text">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-label fxFlex="25">Last Name*</mat-label>
            <mat-form-field fxFlex="60" appearance="outline">
                <input matInput required placeholder="Last Name" [(ngModel)]="user.last_name" formControlName="last_name"
                type="text">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-label fxFlex="25">Email*</mat-label>
            <mat-form-field fxFlex="60" appearance="outline">
                <input matInput required placeholder="Email" [(ngModel)]="user.email" formControlName="email" type="text">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="viewType == 'new admin' && customer.email == loggedUser.user.email">
            <mat-label fxFlex="25">Role:</mat-label>
            <mat-form-field fxFlex="60" appearance="outline" >
                <mat-select [(ngModel)]="user.type" formControlName="type" placeholder="Role" >
                  <mat-option *ngFor="let role of roles" [value]="role.role">{{role.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-label fxFlex="25">Position</mat-label>
            <mat-form-field fxFlex="60" appearance="outline">
                <input matInput placeholder="Position" [(ngModel)]="user.position" formControlName="position" type="text">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="viewType == 'mentors'">
            <mat-label fxFlex="25">School Year Taught</mat-label>
            <mat-form-field fxFlex="60" appearance="outline">
                <input matInput placeholder="Position" [(ngModel)]="user.teacherSchoolYear" formControlName="teacherSchoolYear" type="text">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="viewType == 'mentors'">
            <mat-label fxFlex="25">Age Level Taught</mat-label>
            <mat-form-field fxFlex="60" appearance="outline" >
                <mat-select [(ngModel)]="user.teacherAgeTaught" formControlName="teacherAgeTaught" >
                  <mat-option *ngFor="let level of ageLevelTaught" [value]="level">{{level}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" class="notes-container" fxLayoutAlign="center center">
            <mat-label fxFlex="25">Notes</mat-label>
            <mat-form-field fxFlex="60" appearance="outline">
                <textarea formControlName="notes" [(ngModel)]="user.notes" placeholder="Notes" rows="3" matInput></textarea>
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        </form>
    </div>
    
    </mat-dialog-content>
    <div mat-dialog-actions class="dialog-footer" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button class="cancel-button" (click)="close()">Cancel</button>
        <button mat-raised-button class="save-button" [disabled]="!editUserForm.valid" (click)="saveUser()">Save</button>
    </div>
</div>
