import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Router } from '@angular/router'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'end-subscription-alert',
  templateUrl: './end-subscription-alert.component.html',
  styleUrls: ['./end-subscription-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EndSubscriptionAlertComponent implements OnInit {

  public customerId:any
  constructor(
    private _router: Router,
    
    private _dialogRef:MatDialogRef<EndSubscriptionAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.customerId = data.customerId
  }

  ngOnInit(): void {
  }
  
  goPlans(){
    this._router.navigate(['customers',this.customerId,'plans'])
    this._dialogRef.close('renew')
  }

  close(){
    this._dialogRef.close()
  }
}
