import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

@NgModule({
  imports: [RouterModule.forRoot([
    {
      path: '',
      //loadChildren: () => import('./main/main.module').then(m => m.MainModule)
      loadChildren: () => import('./login/login.module').then(l => l.LoginModule)
    },
    {
      path: 'register',
      loadChildren: () => import('./register/register.module').then(r => r.RegisterModule)
    },
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full'
    }
  ])],
  exports: [RouterModule]
})
export class AppRoutingModule { }
