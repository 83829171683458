import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodComponent } from './payment-method/payment-method.component'
import { MaterialModule } from '../material/material.module'
import { NgbModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgxStripeModule } from 'ngx-stripe';
import { HeaderComponent } from '../shared/layout/header/header.component';
import { FooterComponent } from '../shared/layout/footer/footer.component';
import { ResendModalComponent } from './resend-modal/resend-modal.component';
import { BannerCasllsComponent } from '../shared/dialogs/banner-caslls/banner-caslls.component';

@NgModule({
  declarations: [ PaymentMethodComponent, HeaderComponent, FooterComponent, ResendModalComponent, BannerCasllsComponent ],
  imports: [ CommonModule, MaterialModule, NgbModule, NgxStripeModule ],
  exports: [ PaymentMethodComponent, HeaderComponent, FooterComponent, ResendModalComponent, BannerCasllsComponent],
  
})
export class ComponentsModule { }
