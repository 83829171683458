import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrganizationModel } from '../models/organization/organization.model'

@Injectable()
export class OrganizationDataService {

  organizationSource = new BehaviorSubject<OrganizationModel>(null);
  clearDateSource = new BehaviorSubject<boolean>(false);
  organization = this.organizationSource.asObservable()
  clearDateFlag = this.clearDateSource.asObservable()
  constructor() { }

  setOrganization(date: any) {
    this.organizationSource.next(date)    
  }

  clearDate(flag: boolean) {
    this.clearDateSource.next(flag)
  }

}