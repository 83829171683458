import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Router } from '@angular/router'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'deactivate-user-alert',
  templateUrl: './deactivate-user-alert.component.html',
  styleUrls: ['./deactivate-user-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeactivateUserAlertComponent implements OnInit {

  public customerEndDate:any
  public customerId:any

  constructor(
    private _router: Router,
    private _dialogRef:MatDialogRef<DeactivateUserAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
  }

  close(){
    this._dialogRef.close()
  }
}
