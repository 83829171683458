import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { AuthGuard } from '../shared/services/auth.Guard';
import { LoginForgotComponent } from './login-forgot/login-forgot.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login', component: LoginComponent,
    children: [
      { 
        path: 'platform', 
        loadChildren: () => import('../main/main.module').then(m => m.MainModule),
        canActivate: [AuthGuard]
      },  
    ]
  },
  {
    path: 'login-forgot',
    component: LoginForgotComponent
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
