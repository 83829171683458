import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomerDataService } from '../../shared/services/customers.service';
import { CustomersApiService } from '../../customers/customers-api.service'
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { AlertService } from '../../alert/alert.service'
@Component({
  selector: 'payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentMethodComponent implements OnInit {

  public POForm: FormGroup
  public purchaseOrderNumber: number
  public POFile: File
  public creditCardForm:FormGroup
  public order: any = []
  public validate: boolean = false
  public stripeTest: FormGroup;
  public selectedPlan:any
  public selected = new FormControl(0)
  public isHigherPlan: any = false
  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  constructor(
    public _customerService: CustomerDataService,
    private _customersApiService: CustomersApiService,
    private alertService: AlertService
  ) { }
  
  @Output() sendOrder = new EventEmitter()
  @Output() sendPONumber = new EventEmitter()
  @Output() creditCardEvent = new EventEmitter()
  @Output() formValue = new EventEmitter()
  @Output() selectedTab = new EventEmitter()
  
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @ViewChild('add1') add1: ElementRef
  @ViewChild('add2') add2: ElementRef
  @ViewChild('city') city: ElementRef
  @ViewChild('state') state: ElementRef
  @ViewChild('zip') zip: ElementRef
  @ViewChild('name') name: ElementRef
  @ViewChild('t1', {static: false}) add1Tooltip: NgbTooltip
  @ViewChild('t3', {static: false}) cityTooltip: NgbTooltip
  @ViewChild('t4', {static: false}) stateTooltip: NgbTooltip
  @ViewChild('t5', {static: false}) zipCodeTooltip: NgbTooltip
  @ViewChild('t6', {static: false}) POTooltip: NgbTooltip
  @ViewChild('t7', {static: false}) nameTooltip: NgbTooltip
  @ViewChild('PONumber') purchaseNumber: ElementRef

  ngOnInit(): void {
    this.buildForm()
    
    this._customerService.selectedPlan.subscribe(plan => {
      this.selectedPlan = plan
    })
    
    if(this.selectedPlan){
      if(this.selectedPlan.id == 'plan-five-1yr' || this.selectedPlan.id == 'plan-six-1yr' || this.selectedPlan.id == 'plan-seven-1yr' || this.selectedPlan.id == 'plan-eight-1yr'){
        this.isHigherPlan = true
        this.selectedTab.emit(0)
      } else { 
        this.isHigherPlan = false
      }
    }
    
  }

  public buildForm() {
    this.POForm = new FormGroup({
      PONumber: new FormControl('', Validators.required),
    })

    this.creditCardForm = new FormGroup({
      address1: new FormControl('', Validators.required),
      address2: new FormControl(''),
      city: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(2), Validators.pattern('[a-zA-Z ]*')]),
      zipCode: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required)
    })
  }

  public creditInfo(event) { 
    this._customerService.setCCInfo(event)
    this.formValue.emit(this.order)
  }
  
  public validateForm(){
    if(this.selected.value == 0){
      if(!this.creditCardForm['controls'].address1.value){
        this.add1.nativeElement.focus()
        this.add1Tooltip.open()
        setTimeout(() => {
          this.add1Tooltip.close()
        }, 5000);
        return;
      } else if(!this.creditCardForm['controls'].city.value){
        this.city.nativeElement.focus()
        this.cityTooltip.open()
        setTimeout(() => {
          this.cityTooltip.close()
        }, 5000);
        return;
      } else if (!this.creditCardForm['controls'].state.value){
        this.state.nativeElement.focus()
        this.stateTooltip.open()
        setTimeout(() => {
          this.stateTooltip.close()
        }, 5000);
        return;
      } else if(!this.creditCardForm['controls'].zipCode.value) {
        this.zip.nativeElement.focus()
        this.zipCodeTooltip.open()
        setTimeout(() => {
          this.zipCodeTooltip.close()
        }, 5000);
        return;
      } else if(!this.creditCardForm['controls'].name.value) {
        this.name.nativeElement.focus()
        this.nameTooltip.open()
        setTimeout(() => {
          this.nameTooltip.close()
        }, 5000);
        return;
      } else if(!this.card['controls']){
         return true
      }
    } else {
      if(!this.POForm['controls'].PONumber.value) {
         this.purchaseNumber.nativeElement.focus()
         this.POTooltip.open()
         setTimeout(() => {
           this.POTooltip.close()
         }, 5000);
         return
      } else {
        return true
      }
    }
  }

  public uploadPDF(file:FileList) {
 
    if (file.length === 0)
      return;
    this.POFile = file[0]
    this.sendOrder.emit(file[0])
    // this._customersApiService.uploadPDF(this.POFile).subscribe(res => {
    //   if(res.error) {
    //     this.alertService.warning('Error uploading PDF file')
    //   } else {
    //     this.sendOrder.emit(res.data)
    //     this.alertService.success('File Upload Successfully')
    //   }
    // })
    
  }

  public emitChangedTab(event) {    
    this.selectedTab.emit(this.selected.value)
  }

  public changePONumber() {
    this.sendPONumber.emit(this.purchaseOrderNumber)
  }
}
