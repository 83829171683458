import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { BehaviorSubject, Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Accept': 'application/json, text/javascript, */*; q=0.01',
    'Accept-Language': 'es-ES,es;q=0.9',
  })
};

@Injectable({
  providedIn: 'root'
})
export class CustomersApiService {
  public url = AppConfig.settings.hosts.api
  public plansName = [
    {id: "plan-one-1yr", name: "Plan 1 (1-24 Active Students) 1 Yr - $375", shortName: "Plan One", studentRange: "1 - 24", price: "375.00", limit: 24, years: 1, isPlanSelected: false },
    {id: "plan-two-1yr", name: "Plan 2 (25-49 Active Students) 1 Yr - $500", shortName: "Plan Two", studentRange: "25 - 49", price: "500.00", limit: 49, years: 1, isPlanSelected: false },
    {id: "plan-three-1yr", name: "Plan 3 (50-74 Active Students) 1 Yr - $750", shortName: "Plan Three", studentRange: "50 - 74", price: "750.00", limit: 74, years: 1, isPlanSelected: false },
    {id: "plan-four-1yr", name: "Plan 4 (75-149 Active Students) 1 Yr - $1,050", shortName: "Plan Four", studentRange: "75 - 149", price: "1050.00", limit: 149, years: 1, isPlanSelected: false },
    {id: "plan-five-1yr", name: "Plan 5 (150-249 Active Students) 1 Yr - $1,950", shortName: "Plan Five", studentRange: "150 - 249", price: "1950.00", limit: 249, years: 1, isPlanSelected: false },
    {id: "plan-six-1yr", name: "Plan 6 (250-349 Active Students) 1 Yr - $3,000", shortName: "Plan Six", studentRange: "250 - 349", price: "3000.00", limit: 349, years: 1, isPlanSelected: false },
    {id: "plan-seven-1yr", name: "Plan 7 (350-500 Active Students) 1 Yr - $4,500", shortName: "Plan Seven", studentRange: "350 - 500", price: "4500.00", limit: 500, years: 1, isPlanSelected: false },
    {id: "plan-eight-1yr", name: "Plan 8 (501-750 Active Students) 1 Yr - $6,000", shortName: "Plan Eight", studentRange: "501 - 750", price: "6000.00", limit: 750, years: 1, isPlanSelected: false },
    {id: "plan-nine-1yr", name: "Plan 9 (751-1000 Active Students) 1 Yr - $8,000", shortName: "Plan Nine", studentRange: "751 – 1000", price: "8000.00", limit: 1000, years: 1, isPlanSelected: false }
  ]
  private planAction = new BehaviorSubject('renew')
  public specificAction = this.planAction.asObservable()
  
  constructor( 
    private httpClient: HttpClient,
  ) { }

  selectAction(action) {
    this.planAction.next(action)
  }

  getCustomer1(id) {
    return this.httpClient.get<any>(`${this.url}customers/${id}`)
  }

  getCustomer(id) {
    return this.httpClient.get<any>(`${this.url}customers/${id}`)
  }

  getCustomerUsers(id) {
    return this.httpClient.get<any>(`${this.url}users?customerId=${id}&group=primary`)
  }

  getCustomerAdmins(customerId:number, role:string, archived?:boolean, id?:number): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("customer_id",customerId.toString());
    queryParams = queryParams.append("role", role);
    if (id) 
      queryParams = queryParams.append("org_id",id.toString());
    queryParams = queryParams.append("archived", archived.toString())
    return this.httpClient.get<any>(`${this.url}users`,{params:queryParams})
  }

  getOrganizations(id) {
    return this.httpClient.get<any>(`${this.url}customer/${id}/organizations`)
  }

  addOrganization(org) {

    return this.httpClient.post<any>(`${this.url}customer/${org.customerId}/organization`, org )
  }
  
  editCustomer(customer) {
    return this.httpClient.put<any>(`${this.url}customer/${customer.id}`, customer)
  }

  archiveOrganization(customer) {
    return this.httpClient.put<any>(`${this.url}archived/Organization${customer.id}`, {customer: customer})
  }

  getOrders(customerId){
    return this.httpClient.get<any>(`${this.url}customer/${customerId}/orders`)
  }

  editOrder(order, orderId) {
    return this.httpClient.put<any>(`${this.url}orders/${orderId}`, order)
  }

  resendEmail(id, senderId) {
    return this.httpClient.get<any>(`${this.url}users/invite/?receiverId=${id}&senderId=${senderId}`)
  }

  sendOrders(order) {
    var form_data = new FormData();
    for ( var key in order ) {
      form_data.append(key, order[key]);
    }
    return this.httpClient.post<any>(`${this.url}plan-payment/customer/${order.customerId}`, form_data)
  }

  uploadPDF(file) {
    let fd = new FormData()
    fd.append('file-po-pdf', file, 'po-org.pdf');
    fd.append('action', 'po-file');
    return this.httpClient.post<any>(`${this.url}files/upload.php`, fd)
  }
}
