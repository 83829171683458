import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModel } from '../../models/user/user.model';
import { GeneralApiService } from '../../services/general-api.service';
import { AlertService } from '../../../alert/alert.service'

@Component({
  selector: 'create-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateModalComponent implements OnInit {

  public editUserForm: FormGroup;
  public sessionUser: any = localStorage.getItem('user');
  public user: UserModel = new UserModel()
  public organizationId: any = localStorage.getItem('organizationId');
  public customer: any = localStorage.getItem('customer');
  public viewType: any = null;
  public customerId: any = localStorage.getItem('organizationCustomer');
  public loggedUser: any = JSON.parse(localStorage.getItem('user'));
  public ageLevelTaught = ["ECI (0 to 3 years)", "Early Childhood (3 years to 6 years)", "Elementary (Grades K to 5)", "Middle School (Grades 6 to 8)", "High School (Grades 9 to 12)", "All (Birth to 21 years)" ]
  public roles = [{role:'Super-Admin', label:'Account Admin'}, {role:'Billing', label:'Billing'}]
  constructor(
    private _apiService: GeneralApiService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<CreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.organizationId = data.organizationId;
    this.viewType = data.viewType;

  }

  ngOnInit(): void {
    this.sessionUser = JSON.parse(this.sessionUser)
    this.user.type = this.roles[0].role;
    this.customer = JSON.parse(this.customer);
    console.log(this.customer, 'custoer');
    console.log(this.loggedUser,'us')
    this.buildForm();
    if( this.viewType == 'mentors' ) {
      this.user.teacherAgeTaught = "ECI (0 to 3 years)"
    }
  }
  private buildForm() {
    this.editUserForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      type: new FormControl(''),
      position: new FormControl(''),
      teacherSchoolYear: new FormControl(''),
      teacherAgeTaught: new FormControl(''),
      notes: new FormControl(''),
    })
  }
  public saveUser() {

    if( this.viewType == 'admin'){
      this.user.group = 'admin';
      this.user.type = 'Admin';
    }
    if( this.viewType == 'mentors' ) {
      this.user.group = 'teacher';
      this.user.type = 'Teacher';
    }
    if( this.viewType == 'read-only'){
      this.user.group = 'teacher';
      this.user.type = 'Read-Only';
    }
    if (this.viewType == 'new admin') {
      this.user.group = 'primary';
      // this.user.type = 'Super-Admin'
    }
    let customer = JSON.parse( localStorage.getItem('customer'));
    this.user.customerId = this.customerId? parseInt(this.customerId) : customer.id;
    if(this.organizationId){
      this.user.org_id = parseInt(this.organizationId);
    }
    this.user.status = 'Pending';
    this.user.senderId = this.sessionUser.user.id;
    this._apiService.createUser(this.user).subscribe(res => {
      this.dialogRef.close(res)
      
    }, error => {
      this.alertService.warning('Email already exists');      
    })
  }
  close(){
    this.dialogRef.close();
  }
}
