import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Router } from '@angular/router'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'end-period-alert',
  templateUrl: './end-period-alert.component.html',
  styleUrls: ['./end-period-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EndPeriodAlertComponent implements OnInit {

  public customerEndDate:any
  public customerId:any

  constructor(
    private _router: Router,
    private _dialogRef:MatDialogRef<EndPeriodAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.customerEndDate = new Date(data.endDate)
    this.customerId = data.customerId
  }

  ngOnInit(): void {
  }
  
  goPlans(){
    this._router.navigate(['customers',this.customerId,'plans'])
    this._dialogRef.close()
  }

  close(){
    this._dialogRef.close()
  }
}
