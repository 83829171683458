<div class="container-login-forgot" fxLayout="column" fxLayoutAlign="start center">
    <div [ngClass] ="{'shake': shakeIt, 'card': true}">
        <h2 class="card-header" fxLayoutAlign="center">Recover your Password</h2>
        <div class="card-body">
            <p>Enter your email to receive instructions on how to recover your password.</p>
            <form name="recoverForm" [formGroup]="recoverForm" (ngSubmit)="sendEmail()">
                <div class="form-group">
                    <input type="email" (change)="isEmpty()" class="form-control form-control-lg" [(ngModel)]="email" placeholder="Email" formControlName="email">
                    <mat-error *ngIf="recoverForm.get('email').hasError('email')">Insert a valid email format</mat-error>
                </div>
                <button mat-button type="submit" class="btn sign-btn" [disabled]="!recoverForm.valid" [ngClass]="{'orange' : buttonError, 'green': !buttonError}" >{{isLoading ? buttonLog: 'Reset Password' }}</button>
            </form>
        </div>
    </div>
    <div class="forgot-link" fxLayoutAlign="center center">
        <a href="" [routerLink]="['/login']">Login</a>
    </div> 
</div>