<div class="container-modal" fxFill fxLayout="column">
    <div class="title" fxLayoutAlign="center">
        <strong>ACCOUNT ARCHIVED</strong>
    </div>
    <div class="body" fxLayout="column" fxLayoutAlign="space-between" >
        <div class="modal-message" fxFlexAlign="center" fxLayoutAlign="center center">
            <span>Your account has been archived. Please contact Isela Fennell at ifennell@sunshinecottage.org with any questions you may have.</span>
        </div>
        <div class="modal-buttons" fxLayout="row" fxLayoutAlign="end">
            <button mat-raised-button class="archive-button btn" (click)="close()">
                <span>Ok</span>
            </button>
        </div>
    </div>
</div>
