<div class="container-modal" fxFill fxLayout="column">
    <div class="title" fxLayoutAlign="center">
        <strong>RENEW SUBSCRIPTION</strong>
    </div>
    <div class="body" fxLayout="column" fxLayoutAlign="space-between" >
        <div class="modal-message" fxFlexAlign="center" fxLayoutAlign="center center">
            <span>Your plan has ended. To regain access to the eCASLLS, please renew your subscription now. Thank you!</span>
        </div>
        <div class="modal-buttons" fxLayout="row" fxFlexAlign="space-between"  fxLayoutAlign="space-between">
            <button mat-raised-button (click)="close()" >
                <span>Renew Later</span>
            </button>
            <button mat-raised-button class="archive-button btn" (click)="goPlans()">
                <span>Renew</span>
            </button>
        </div>
    </div>
</div>
