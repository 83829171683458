import { Component, ComponentRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { GeneralApiService } from '../../shared/services/general-api.service'
import { first } from 'rxjs/operators';
import { CustomersApiService } from '../../customers/customers-api.service';
import { AppConfig } from '../../app.config';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/alert/alert.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ResetPasswordComponent implements OnInit {

  public authForm: FormGroup;
  public submitted:boolean  = false;
  public returnUrl: string;
  public isLoading: any = false;
  public loginForm: FormGroup;
  public shakeIt = false;
  public buttonLog:any = 'Loading';
  public buttonError:boolean = false;
  public url = AppConfig.settings.hosts.web;
  public token:string;
  public email:string;

  constructor(
    private _authService: AuthService,
    private _generalApiService: GeneralApiService,
    private _customerService: CustomersApiService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public alert: AlertService
   // private componentRef: ComponentRef<any>
  ) { }
  
  ngOnInit(): void {
    const emailPattern = '^^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$';
    this.token =this.route.snapshot.paramMap.get('token');

    this._generalApiService.checkToken(this.token).subscribe(res =>{
      this.email = res.email
      this.authForm = this.formBuilder.group({
        email: (this.email),
        password: ['', Validators.compose([
            Validators.required,
            Validators.minLength(3)
        ])],
        password_confirmation: ['', Validators.compose([
            Validators.required,
            Validators.minLength(3)
          ])]    
        });
    }, err => {
      this.router.navigate(['/login'])
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.authForm.controls; }

  getcustomerInfo(customerId){
    this._customerService.getCustomer(customerId).subscribe( res => {
      localStorage.setItem('customerPaymentPlan', res.paymentPlan)
    })
  }
  
  login(){
    if (this.authForm.value.password != this.authForm.value.password_confirmation) {
      
      this.alert.warning('Password not match')
      return;
    }
    if (this.authForm.invalid) {
      return;
    }

    this.isLoading = true;
    this._generalApiService.resetPassword(this.token, this.authForm.value ).subscribe( data => {
      this.alert.success('Password Reset Successfully');
      this.router.navigate(['/login'])
    }, e => {
      this.shakeDialog();
      this.errorMessage();
      //  this.alertService.warning(e.error.data.message);
    });
      
  }
  shakeDialog() {
    this.shakeIt = true;
    setTimeout((arg) => {
      this.shakeIt = false;
    },
    800);
  }
  errorMessage(){
    this.buttonLog = 'Incorrect Email/Password';
    this.buttonError = true;
    setTimeout(arg => {
      this.isLoading = false;
      this.buttonLog = 'Loading';
      this.buttonError = false
     },
     3000); 
  }
}
