import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { MainRoutingModule } from './main-routing.module';
import { HeaderComponent } from '../shared/layout/header/header.component';
import { MaterialModule } from '../material/material.module';
import { FooterComponent } from '../shared/layout/footer/footer.component';
import { EditUserModalComponent } from '../shared/dialogs/edit-user-dialog/edit-user-dialog.component';
import { CreateModalComponent } from '../shared/dialogs/create-modal/create-modal.component'
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { OrganizationDataService } from '../shared/services/organization.service'
import { CustomerDataService } from '../shared/services/customers.service'
import { EndSubscriptionAlertComponent } from '../shared/dialogs/end-subscription-alert/end-subscription-alert.component'
import { GracePeriodAlertComponent } from '../shared/dialogs/grace-period-alert/grace-period-alert.component'
import { EndPeriodAlertComponent } from '../shared/dialogs/end-period-alert/end-period-alert.component'
import { DeactivateUserAlertComponent } from '../shared/dialogs/deactivate-user-alert/deactivate-user-alert.component';
import { ComponentsModule } from '../components/components.module';
import { TermsComponent } from '../terms/terms.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: "#27ae60",
  fgsType: "wandering-cubes",
  fgsPosition: "center-center",
  fgsSize: 80,
  blur: 10,
  overlayColor: "rgba(40, 40, 40, 0.8)",
  hasProgressBar: true,
  pbColor: "#27ae60",
  pbThickness: 6,
  pbDirection: "ltr",
  fastFadeOut: true
};

@NgModule({
  declarations: [MainComponent, DeactivateUserAlertComponent, EndPeriodAlertComponent, EndSubscriptionAlertComponent, GracePeriodAlertComponent, EditUserModalComponent, CreateModalComponent],
  imports: [
    CommonModule,
    MainRoutingModule,
    MaterialModule,
    ComponentsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
  ],
  providers: [OrganizationDataService, CustomerDataService],
  entryComponents:[EditUserModalComponent, CreateModalComponent],

})
export class MainModule { }
