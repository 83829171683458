import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'sweb-sunshine';

  constructor(authService: AuthService, private router: Router) {
    const user = authService.getUser();

  }
  
}
