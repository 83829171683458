import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { AuthGuard } from '../shared/services/auth.Guard';

const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      { 
        path: '', 
        redirectTo: '', 
        pathMatch: 'full' 
      },
      {
        path: 'register-admin/:userId',
        loadChildren: () => import('../register-admin/register-admin.module').then(r => r.RegisterAdminModule)
      },
      {
        path: 'register-teacher/:userId',
        loadChildren: () => import('../register-teacher/register-teacher.module').then(r => r.RegisterTeacherModule)
      },
      {
        path: 'organization/:organizationId',
        loadChildren: () => import('../organization/organization.module').then(o => o.OrganizationModule)
      },
      {
        path: 'students/:studentId',
        loadChildren: () => import('../students/students.module').then(p => p.StudentsModule)
      },
      {
        path: 'customers/:customersId',
        loadChildren: () => import('../customers/customers.module').then(c => c.CustomersModule )
      },
      {
        path: 'customer',
        loadChildren: () => import('../dashboard/dashboard.module').then(d => d.DashboardModule)
      },
      {
        path: 'register',
        loadChildren: () => import('../register/register.module').then(r => r.RegisterModule)
      },
      {
        path: 'terms',
        loadChildren: () => import('../terms/terms.module').then(t => t.TermsModule)
      }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class MainRoutingModule { }
