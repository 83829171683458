export class UserModel {
    id: number;
    created: Date;
    customerId: number;
    email: string;
    firstName: string;
    first_name:string;
    last_name:string;
    org_id:number;
    group: string;
    img: any;
    roles: any;
    lastName: string;
    notes: string;
    organizationId: number;
    password: string;
    position: string;
    senderId: string;
    status: string;
    teacherAgeTaught: string;
    teacherSchoolYear: string;
    teacherCurrentStudents: string;
    teacherPastStudents: string;
    user: any;
    type: string;
    modified: Date;

    constructor () {
        this.created = null;
        this.customerId = null;
        this.email = null;
        this.firstName = null;
        this.group = null;
        this.img = null;
        this.lastName = null;
        this.notes = null;
        this.organizationId = null;
        this.password = null;
        this.position = null;
        this.senderId = null;
        this.status = null;
        this.teacherAgeTaught = null;
        this.teacherSchoolYear = null;
        this.teacherCurrentStudents = null;
        this.teacherPastStudents = null;
        this.user = null;
        this.type = null;
        this.modified = null;
    }
}