import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Router } from '@angular/router'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'grace-period-alert',
  templateUrl: './grace-period-alert.component.html',
  styleUrls: ['./grace-period-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GracePeriodAlertComponent implements OnInit {

  public customerEndDate:any
  public planDaysLeft:any 
  public date: any
  public customerId: any

  constructor(
    private _router: Router,
    private _dialogRef:MatDialogRef<GracePeriodAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.customerEndDate = data.customerEndDate
    this.date = data.date
    this.customerId = data.customerId
  }

  ngOnInit(): void {    
    
    this.planDaysLeft = Math.floor((new Date(this.customerEndDate).getTime() - new Date(this.date).getTime()) / 1000 / 60 / 60 / 24)
  }
  
  goPlans(){
    this._router.navigate(['customers', this.customerId,'plans'])
    this._dialogRef.close()
  }

  close(){
    this._dialogRef.close()
  }
}
