<div class="container-reset" fxLayout="row" fxLayoutAlign="center" *ngIf="email">
    <div [ngClass] ="{'shake': shakeIt, 'card': true}">
        <h4 class="card-header" fxLayoutAlign="center"><img src="{{url}}assets/images/sunshine-logo.png" alt=""></h4>
        <div class="card-body">
            <form name="loginForm" [formGroup]="authForm" (ngSubmit)="login()">
                <div class="form-group">
                    <input type="password" class="form-control form-control-lg" placeholder="New Password" formControlName="password">
                </div>
                <div class="form-group">
                    <input type="password" class="form-control form-control-lg" placeholder="Confirm Password" formControlName="password_confirmation">
                </div>
                <button type="submit" class="btn sign-btn" [ngClass]="{'red' : buttonError, 'green': !buttonError}" >{{isLoading ? buttonLog: 'Change My Password' }}</button>
            </form>
        </div>
    </div>
</div>