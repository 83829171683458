import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component'
import { LoginRoutingModule } from './login.routing,module';
import { LoginForgotComponent } from './login-forgot/login-forgot.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [ LoginComponent, LoginForgotComponent, ResetPasswordComponent ],
  imports: [
    CommonModule,
    MaterialModule,
    LoginRoutingModule
  ]
})
export class LoginModule { }
