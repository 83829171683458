import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse 
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptorService implements HttpInterceptor {
  
  constructor(public authenticationService: AuthService, private _router:Router) {}
  
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
      let accessToken: string = this.authenticationService.getToken();

    // Set headers for requests that require authorization.
    if (accessToken) {
      const authenticatedRequest = request.clone({
        headers: request.headers.set(
          'Authorization',
          `Bearer ${this.authenticationService.getToken()}`
        )
      });
      // Request with authorization headers
      return next.handle(authenticatedRequest).pipe(catchError(err => {
            if ([401, 403].includes(err.status) && accessToken) {
                // auto logout if 401 or 403 response returned from api
                this.authenticationService.logout();
            }

            const error = err.error?.message || err.statusText;
            console.error(err);
            return throwError(() => error);
        })
    );
    } else {
      // Request without authorization header
      return next.handle(request);
    }
  }
}