<div fxFill fxLayoutAlign="center" *ngIf="!close">
  <div class="banner-container" fxLayout="column" fxLayoutAlign="center center" fxFlex="78" >
    <div class="banner-header" fxLayout="row" fxLayoutAlign="space-between">
      <div class="banner-logo">
        <div fxLayout="row">
            <div class="icon-banner icon-80" fxLayoutAlign="center center">
                <mat-icon fontSet="fas" class="g-text-white" fontIcon="fa-exclamation"></mat-icon>
            </div>
            <div class="text-info" fxLayout="column" fxLayoutAlign="center center">
                <span><strong>Welcome to eCASLLS 2.0!</strong></span>
            </div>
        </div>
      </div>
      <div class="close-btn">
        <button mat-icon-button (click)="closeMessage()"><mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon></button>
      </div>
    </div>
    <div class="banner-body" fxLayout="column" fxLayoutAlign="space-between" >
      <p>We've updated the entire platform to make everything faster and easier to use. If you're an existing user, we recommend the following to get the full eCASLLS 2.0 experience:</p>
      <p><strong>1.</strong> We recommend clearing your browser's cache. You can do this under your browser's preferences/settings</p>
      <p><strong>2.</strong> For the best experience, please use the platform in a private/incognito window. You can open a private window by going to File > New Incognito Window.</p>  
    </div>
  </div>
</div>
