<div fxLayout="row" class="footer-container" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="100" class="terms" fxLayout="center center">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
            <a href="/platform/terms">
                Terms of Use & Privacy Policy
            </a>
        </div>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
            <p>The content of eCASLLS may not be reproduced, reprinted, or rebroadcast in any form without the express written consent of eCASLLS and Sunshine Cottage School for Deaf Children. The eCASLLS should only be used as it is intended.
            </p>
        </div>
    </div>
</div>