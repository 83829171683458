import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StudentModel } from 'src/app/shared/models/student/student.model';
import { GeneralApiService } from 'src/app/shared/services/general-api.service';
import { UserModel } from 'src/app/shared/models/user/user.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'edit-user-dialog',
    templateUrl: 'edit-user-dialog.component.html',
    styleUrls: ['edit-user-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EditUserModalComponent implements OnInit {
  public editUserForm: FormGroup;
  public user: UserModel = new UserModel()
  public userProfileImg: any
  private imgToUpload: any
  constructor(
    public dialogRef: MatDialogRef<EditUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    private _sanitizer: DomSanitizer,
    private _apiService: GeneralApiService,
    private _snackBar: MatSnackBar,
    ) {
      this.user.id = data.id
    }

  onDoneClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.buildForm()
    this.getUser()
  }

  public close() {
    this.dialogRef.close()
  }


  public saveUser() {
    if(this.imgToUpload) {
      this.user.img = this.imgToUpload
    }
    this.user.first_name = this.user.firstName;
    this.user.last_name = this.user.lastName;
    this._apiService.editUserSingle(this.user).subscribe(res => {
      this.dialogRef.close(this.user)
    }, error => {
      console.log(error)
    })
  }

  private getUser() {
    this._apiService.getUser(this.user.id).subscribe((res: any) => {
      if(res) {
        this.user = res
        console.log(this.user,'en el get del edit');
        
        let tinyUser:any = localStorage.getItem('user');
        tinyUser = JSON.parse(tinyUser);
        console.log(tinyUser,'tinyUser');
        //tinyUser.user = this.user
        //localStorage.setItem('user', JSON.stringify(tinyUser))
      }
    }, error => {
      console.log(error)
    })
  }

  private buildForm() {
    this.editUserForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      position: new FormControl(''),
      notes: new FormControl(''),
      password: new FormControl(''),
    })
  }

  public uploadImg(img) {
    if (img.length === 0)
      return;
    let mimeType = img[0].type;
    if (mimeType.match(/image\/jpg|png|jpeg/) == null) {
      this._snackBar.open("Select a valid format (png, jpg, jpeg) ", 'x', { duration: 3000 });
      return;
    }

    if (img[0].size > 1000000) {
      this._snackBar.open("File exceeds the maximum size", 'x', { duration: 3000 });
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(img[0]);
    reader.onload = (_event) => {
      this.userProfileImg = reader.result.toString()
      this.imgToUpload = reader.result
    }
    
  }
}