
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { formatDate } from '@angular/common'
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EndSubscriptionAlertComponent } from '../shared/dialogs/end-subscription-alert/end-subscription-alert.component'
import { EndPeriodAlertComponent } from '../shared/dialogs/end-period-alert/end-period-alert.component';
import { GracePeriodAlertComponent } from '../shared/dialogs/grace-period-alert/grace-period-alert.component';
import { GeneralApiService } from '../shared/services/general-api.service';
import { CustomersApiService } from '../customers/customers-api.service';
import { OrganizationDataService } from '../shared/services/organization.service';
import { DeactivateUserAlertComponent } from '../shared/dialogs/deactivate-user-alert/deactivate-user-alert.component';
import { CustomerDataService } from '../shared/services/customers.service';
import { AuthService } from '../shared/services/auth.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {
  public userLog:any = localStorage.getItem('user'); 
  constructor(
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private _authService: AuthService,
    private _generalApiService: GeneralApiService,
    private _customerApiService: CustomersApiService,
    private _organizationData: OrganizationDataService,
    private _customerData: CustomerDataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    
    if(this.userLog){
      this.userLog =JSON.parse(this.userLog);
      let user = this.userLog.user
      let id = this.userLog.user.customerId
      if(user.status === 'Archived'){
        const dialogConfig = new MatDialogConfig
        dialogConfig.width = '400px'
        dialogConfig.position = { top: '10px' }
        this.dialog.open(DeactivateUserAlertComponent, dialogConfig ).afterClosed().subscribe(data =>{
          this._authService.logout()
        })
      } else {
        this.sessionUser();
      }    
      if (id){
        //this.router.navigate(['platform/customers/', id])
      }
      let organizationJSON = JSON.parse(localStorage.getItem('organizationInfo'));
      if (organizationJSON) {
        this._organizationData.setOrganization(organizationJSON)
      }
      let customerJSON = JSON.parse(localStorage.getItem('customer'))
      if (customerJSON) {
        this._customerData.setCustomer(customerJSON)
      }
    }
  }

  public sessionUser() {
    let date = sessionStorage.getItem('date')
    let currentDate = formatDate(new Date(date), 'yyyy-MM-dd', 'en' )
    let currentDate2 = new Date(currentDate)
    let user = this.userLog
    sessionStorage.setItem('role', user.type)
    sessionStorage.setItem('group', user.group)
    
    if(user.role != 'Owner'){

      this._customerApiService.getCustomer(user.user.customerId).subscribe(data => {
        let customer = data
        this._customerData.setCustomer(customer)
        let endDate = customer.paymentPlanDateExpiration + "T00:00:00";
        
        const dialogConfig = new MatDialogConfig
        dialogConfig.width = '600px'
        dialogConfig.height = 'auto'
        dialogConfig.position= { top: '10px'}

        let planEndDate = new Date(endDate)
        let gracePeriodEndDate = new Date(endDate)
        gracePeriodEndDate.setDate(gracePeriodEndDate.getDate () + 30)
        let planEndsSoonNotificationStartDate = new Date(endDate)
        planEndsSoonNotificationStartDate.setDate(planEndsSoonNotificationStartDate.getDate() - 61)
        
        sessionStorage.setItem('customer', customer)

        const group = user.user.group
        if (group === 'owner' || user.role == 'Owner') {
          sessionStorage.setItem('role', 'isOwner')
        }
        if(group === 'admin') {
          sessionStorage.setItem('role', 'isAdmin');
        }
        if (group == 'teacher') {
          sessionStorage.setItem('role', 'isTeacher')
        }
        if (user.role == 'Super-Admin' && group != 'primary') {
          sessionStorage.setItem('role', 'isSuperAdmin')
        }
        if (user.role == 'Super-Admin' || user.role == 'Billing') {
          sessionStorage.setItem('role','isHigherAdmin');
        }
        if (user.role == 'Admin' || user.type == 'Admin') {
         sessionStorage.setItem('role', 'isSubAdmin');
        }
        if (user.role == 'Teacher' || group == 'teacher' || user.type == 'Teacher') {
          sessionStorage.setItem('role', 'isTeacherCaslls')
        }
        if (user.role == 'Read-Only'|| group == 'teacher' || user.type == 'Read-Only') {
          sessionStorage.setItem('role', 'isTeacherReadOnly')
        }

        if (currentDate2 > gracePeriodEndDate) {
          dialogConfig.data = { customerId: customer.id }
          this.dialog.open(EndSubscriptionAlertComponent, dialogConfig ).afterClosed().subscribe(data => {
            if(data != 'renew'){
              this._authService.logout()
            }
          });
          sessionStorage.setItem('type', 'Renew');
          sessionStorage.setItem('expired', 'true');
        } else if (currentDate2 > planEndDate) {
          dialogConfig.data = { endDate: endDate, customerId: customer.id } 
          this.dialog.open(EndPeriodAlertComponent, dialogConfig );
          sessionStorage.setItem('type', 'Renew');
          sessionStorage.setItem('endTime', 'true');
        } else if (currentDate2 > planEndsSoonNotificationStartDate) {
          dialogConfig.data = { customerEndDate: endDate, date: date, customerId: customer.id}
          this.dialog.open(GracePeriodAlertComponent, dialogConfig );
          sessionStorage.setItem('type', 'Renew');
          sessionStorage.setItem('gracePeriod', 'true');
        }
      })
    } else {
      sessionStorage.setItem('role', 'isOwner')
    }
  }
}
