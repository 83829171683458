import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/alert/alert.service';
import { GeneralApiService } from 'src/app/shared/services/general-api.service';

@Component({
  selector: 'resend-modal',
  templateUrl: './resend-modal.component.html',
  styleUrls: ['./resend-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResendModalComponent implements OnInit {

  public seederId: any = null
  public id: any = null

  constructor(
    private _generalApiService: GeneralApiService,
    public alertService: AlertService,
    public dialogRef: MatDialogRef<ResendModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.seederId = data.seederId
    this.id = data.id
  }

  ngOnInit(): void {
  }
  
  resendEmail() {
    this._generalApiService.resendUserEmail(this.id,this.seederId).subscribe(data => {
      this.alertService.success('Invitation has been resend')
      this.dialogRef.close();
    }, error => this.alertService.warning('Error resend Invitation'))
  }

  public close() {
    this.dialogRef.close();
  }
}
