import {OnInit, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'banner-caslls',
  templateUrl: './banner-caslls.component.html',
  styleUrls: ['./banner-caslls.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BannerCasllsComponent implements OnInit {

  public close: boolean = false; 

  constructor(

  ) { }

  ngOnInit() {
    let closeStatus = localStorage.getItem('close');
    if(closeStatus === 'true') {
      this.close = true;
    }
  }

  public closeMessage() {
    this.close = !this.close;
    localStorage.setItem('close', this.close.toString())
  }

}
