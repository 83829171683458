import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserModel } from '../models/user/user.model';
import { AppConfig } from '../../app.config';
import { CustomerDataService } from './customers.service';
import { OrganizationDataService } from './organization.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = AppConfig.settings.hosts.api;
  private userSubject: BehaviorSubject<UserModel>;
  public user: Observable<UserModel>;

  constructor(
    private router: Router,
    private httpClient : HttpClient,
    private _customerService: CustomerDataService,
    private _organizationService: OrganizationDataService
  ) { 
    this.userSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
    }

  login(loginForm: FormGroup) {
    const httpOptions = {
      headers: new HttpHeaders({
           'Accept': 'application/json',
          'Content-Type': 'application/json'
      })
    };
    let Form = JSON.stringify(loginForm.value);
    let newform = { data: { User: { email: loginForm.value.email, password: loginForm.value.password } } }
    const structure = new HttpParams()
    .set('data', JSON.stringify(loginForm.value));
    return this.httpClient.post<any>(`${this.url}login`, { email: loginForm.value.email, password: loginForm.value.password}, httpOptions).pipe(map(user => {
      // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
      user.authdata = window.btoa( loginForm.value.email + ':' + loginForm.value.password);
      localStorage.setItem('user', JSON.stringify(user));
      this.userSubject.next(user);
      return user;
    }));
  }
  public get userValue(): UserModel {
    return this.userSubject.value;
  }

  getToken() {
    let token:any = JSON.parse(localStorage.getItem('user'))
    return token ? token.token.accessToken : null;
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  logout(){
    localStorage.clear();
    sessionStorage.clear();
    this.userSubject.next(null);
    this._customerService.setCustomer(null);
    this._organizationService.setOrganization(null);
    this.router.navigate(['/login']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
