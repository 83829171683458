import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public warning(message): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      background: 'rgb(255, 127, 72)',
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    Toast.fire({
      icon: 'warning',
      title: message
    });
  }

  public success(message): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      background: 'rgb(39,174,96)',
      timer: 5000,
      color: 'rgb(255,255,255)',
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    Toast.fire({
      icon: 'success',
      title: message,
      color: 'rgb(255,255,255)'
    });
  }

}
