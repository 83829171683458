<div class="dialog-title" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="95" fxLayout="row" fxLayoutAlign="center">
        <strong>RESEND</strong>
    </div>
    <mat-icon fxFlex="5" fontSet="fas" (click)="close()" fontIcon="fa-times"></mat-icon>
</div>
<div class="modal-container">
   <div class="dialog-body" fxLayout="column" fxLayoutAlign="center">
       <mat-dialog-content>
            <h2>Are you sure you want to resend invitation?</h2>
        </mat-dialog-content>
        <div class="modal-buttons" fxLayout="row" fxFlexAlign="space-between"  fxLayoutAlign="space-between">
            <button mat-raised-button  class="archive-button btn" (click)="close()">
                <span>Cancel</span>
            </button>
            <button mat-raised-button class="g-background-green g-text-white" (click)="resendEmail()">
                <span>Resend</span>
            </button>
        </div>
   </div>
</div>
