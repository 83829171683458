import { Component, ComponentRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { GeneralApiService } from '../shared/services/general-api.service'
import { first } from 'rxjs/operators';
import { CustomersApiService } from '../customers/customers-api.service';
import { AppConfig } from '../app.config';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LoginComponent implements OnInit {

  public authForm: FormGroup;
  public submitted:boolean  = false;
  public returnUrl: string;
  public isLoading: any = false;
  public loginForm: FormGroup;
  public shakeIt = false;
  public buttonLog:any = 'Loading';
  public buttonError:boolean = false;
  public url = AppConfig.settings.hosts.web;
  public apiUrl = AppConfig.settings.hosts.api;
  public temporalMessage: string = '';
  constructor(
    private _authService: AuthService,
    private _generalApiService: GeneralApiService,
    private _customerService: CustomersApiService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
   // private componentRef: ComponentRef<any>
  ) { 
    if (this._authService.userValue) { 
      
      this.redirect(this._authService.userValue);
    }
  }
  
  ngOnInit(): void {
    const emailPattern = '^^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$';
    this.authForm = this.formBuilder.group({
      email: ['', Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern)
      ])],
      password: ['', Validators.compose([
          Validators.required,
          Validators.minLength(4)
      ])]
  });

  this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.authForm.controls; }

  getcustomerInfo(customerId){
    this._customerService.getCustomer(customerId).subscribe( res => {
      localStorage.setItem('customerPaymentPlan', res.paymentPlan)
    })
  }

  // redirect(user){
  //   if(user.type == 'Super Admin' && user.group){
  //     this.router.navigate(['/customers',user.customerId,'organizations'])  
  //   }
  //   if(user.type == 'Owner') {
  //     this.router.navigate(['/customer'])
  //   }
  //   if(user.type == 'Admin' && user.group != 'primary' || user.group == 'admin') {
  //     this.router.navigate(['/organization',user.organizationId,'teachers'])
  //   }
  //   if(user.type == 'Admin' && user.group == 'primary') {
  //     this.router.navigate(['/customers',user.customerId,'organizations'])
  //   }
  //   if(user.type == 'Caslls' || user.type == 'Read-Only') {
  //     this.router.navigate(['/organization',user.organizationId,'teacher',user.id])
  //   }
  // }
  redirect(user){
    if(user.role == 'Super-Admin' || user.role == 'Billing'){
      this.router.navigate(['/customers',user.user.customerId,'organizations'])  
    }
    if(user.role == 'Owner') {
      this.router.navigate(['/customer'])
    }
    if((user.role == 'Admin'|| user.group == 'admin') && user.user.organizationId) {
      this.router.navigate(['/organization',user.user.organizationId,'teachers'])
    }
    if((user.role == 'Admin'|| user.group == 'admin') && !user.user.organizationId) {
      this.router.navigate(['/customers',user.user.customerId,'organizations'])
    }
    // if(user.role == 'Admin') {
    //   this.router.navigate(['/customers',user.user.customerId,'organizations'])
    // }
    if(user.role == 'Teacher' || user.role == 'Read-Only') {
      console.log('/organization',user.organizationId,'teacher',user.id);
      
      this.router.navigate(['/organization',user.user.organizationId,'teacher',user.user.id])
    }
  }
  login(){
    
    if (this.authForm.invalid) {
      return;
    }
    this.isLoading = true;
    forkJoin([this._generalApiService.pageDate(),this._authService.login(this.authForm).pipe(first())]).subscribe( data => {
      sessionStorage.setItem('date', data[0].date)
      if(data[1].user.roles[0].name != 'Owner'){
        this.getcustomerInfo(data[1].user.customerId);
      }
      this.redirect(data[1]);
      //this.componentRef.destroy();
    }, e => {
      console.log(e);
      if (e.url === (this.apiUrl + 'login') && e.status === 401) {
        this.temporalMessage = e.error.message;
        console.log(this.temporalMessage);
      }
      this.shakeDialog();
      this.errorMessage();
      //  this.alertService.warning(e.error.data.message);
    });
      
    // this._authService.login(this.authForm).pipe(first()).subscribe( data => {
    //   this._generalApiService.pageDate().subscribe(date => {
    //     sessionStorage.setItem('date', date.date)      
    //   })
    //   if(data.user.type !== 'Super Admin'){
    //     this.getcustomerInfo(data.user.customerId);
    //   }
    //   this.redirect(data.user);
    //   //this.componentRef.destroy();
    // }, e => {
    //   console.log(e.error.errors[0].title);
      
    //   this.shakeDialog();
    //   this.errorMessage();
    //   //  this.alertService.warning(e.error.data.message);
    // });
  }
  shakeDialog() {
    this.shakeIt = true;
    setTimeout((arg) => {
      this.shakeIt = false;
    },
    800);
  }
  errorMessage(){
    this.buttonLog = 'Incorrect Email/Password';
    this.buttonError = true;
    setTimeout(arg => {
      this.isLoading = false;
      this.buttonLog = 'Loading';
      this.buttonError = false
     },
     3000); 
  }
}
