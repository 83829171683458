import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { DatePipe } from '@angular/common'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainModule } from './main/main.module';
import { LoginModule } from './login/login.module'
import { MaterialModule } from './material/material.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppConfig } from './app.config';
import { MdePopoverModule } from '@material-extended/mde';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { NgxStripeModule } from 'ngx-stripe';
import { AuthenticationInterceptorService } from './http.interceptor';


/*const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: "#27ae60",
  fgsType: "wandering-cubes",
  fgsPosition: "center-center",
  fgsSize: 80,
  blur: 10,
  overlayColor: "rgba(40, 40, 40, 0.8)",
  hasProgressBar: true,
  pbColor: "#27ae60",
  pbThickness: 6,
  pbDirection: "ltr",
  fastFadeOut: true
};*/

//const stripeKey = AppConfig.settings.stripe.key;
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    TooltipModule,
    ChartsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MainModule,
    LoginModule,
    HttpClientModule,
    MdePopoverModule,
    //NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    //NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    NgbModule,
    NgxStripeModule.forRoot('pk_test_51K4tfABTCKsjDDuYQUD0v4mooV96U8iDEyui77ZIjAnmJZXdadxwXs0D6tpDPY1RtUOx6l4mR7Dl91ff22rmY32U00a9R7rOju'),
  ],
  providers: [
    AppConfig,
    {
        provide: APP_INITIALIZER,
        useFactory: initializeApp,
        deps: [AppConfig],
        multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}
