import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerModel } from '../models/customer/customer.model'

@Injectable()
export class CustomerDataService {

  public plansName = [
    {id: "plan-one-1yr", name: "Plan 1 (1-24 Active Students) 1 Yr - $375", shortName: "Plan One", studentRange: "1 - 24", price: "375.00", limit: 24, years: 1, isPlanSelected: false },
    {id: "plan-two-1yr", name: "Plan 2 (25-49 Active Students) 1 Yr - $500", shortName: "Plan Two", studentRange: "25 - 49", price: "500.00", limit: 49, years: 1, isPlanSelected: false },
    {id: "plan-three-1yr", name: "Plan 3 (50-74 Active Students) 1 Yr - $750", shortName: "Plan Three", studentRange: "50 - 74", price: "750.00", limit: 74, years: 1, isPlanSelected: false },
    {id: "plan-four-1yr", name: "Plan 4 (75-149 Active Students) 1 Yr - $1,050", shortName: "Plan Four", studentRange: "75 - 149", price: "1050.00", limit: 149, years: 1, isPlanSelected: false },
    {id: "plan-five-1yr", name: "Plan 5 (150-249 Active Students) 1 Yr - $1,950", shortName: "Plan Five", studentRange: "150 - 249", price: "1950.00", limit: 249, years: 1, isPlanSelected: false },
    {id: "plan-six-1yr", name: "Plan 6 (250-349 Active Students) 1 Yr - $3,000", shortName: "Plan Six", studentRange: "250 - 349", price: "3000.00", limit: 349, years: 1, isPlanSelected: false },
    {id: "plan-seven-1yr", name: "Plan 7 (350-500 Active Students) 1 Yr - $4,500", shortName: "Plan Seven", studentRange: "350 - 500", price: "4500.00", limit: 500, years: 1, isPlanSelected: false },
    {id: "plan-eight-1yr", name: "Plan 8 (501-750 Active Students) 1 Yr - $6,000", shortName: "Plan Eight", studentRange: "501 - 750", price: "6000.00", limit: 750, years: 1, isPlanSelected: false },
    {id: "plan-nine-1yr", name: "Plan 9 (751-1000 Active Students) 1 Yr - $8,000", shortName: "Plan Nine", studentRange: "751 – 1000", price: "8000.00", limit: 1000, years: 1, isPlanSelected: false }
  ]
  
  customerSource = new BehaviorSubject<CustomerModel>(null)
  customerActualPlan = new BehaviorSubject<any>(null)
  selectedPlan = new BehaviorSubject<any>(null)
  positionPlan = new BehaviorSubject<number>(null)
  planAction = new BehaviorSubject('Renew')
  stripeCCInfo = new BehaviorSubject(null)
  typeSource = new BehaviorSubject<any>(null)
  orderPurchase = new BehaviorSubject<any>(null)
  
  specificAction = this.planAction.asObservable()
  customer = this.customerSource.asObservable()
  actualPlan = this.customerActualPlan.asObservable()
  plan = this.customerActualPlan.asObservable()
  position = this.positionPlan.asObservable()
  type = this.typeSource.asObservable()
  stripeInfo = this.stripeCCInfo.asObservable()
  order = this.orderPurchase.asObservable()

  constructor() { }

  setCustomer(data: any) {
    this.customerSource.next(data)    
    if(data){
      this.setPlan(data.current_plan)
    }
  }

  setType(data:any){
    localStorage.setItem('type', data);
    this.typeSource.next(data)
  }

  setSelectedPlan(selectedPlan:any){
    this.selectedPlan.next(selectedPlan)
  }
  
  setPlan(actualPlan:any) {
    let position = 0
    this.plansName.forEach(plan => {
      if(this.plansName[actualPlan -1].id == plan.id){
        this.customerActualPlan.next(plan)
        this.positionPlan.next(position)
      }
      position += 1
    })
  }

  setCCInfo(creditCardInfo: any ){
    this.stripeCCInfo.next(creditCardInfo)
  }

  setOrder(order:any){
    this.orderPurchase.next(order);
  }
}