import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.userValue;
        const currentUrl = state.url;
        if (user) {
            // logged in so return true
            if (user && (currentUrl === '/' || currentUrl === '/login')) {
                this.redirect(user);
              }
            return true;
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        // not logged in so redirect to login page with the return url
    }

    redirect(user){
        if(user.role == 'Super-Admin' || user.role == 'Billing'){
          this.router.navigate(['/customers',user.user.customerId,'organizations'])  
        }
        if(user.role == 'Owner') {
          this.router.navigate(['/customer'])
        }
        if(user.role == 'Admin'|| user.group == 'admin') {
          this.router.navigate(['/organization',user.user.organizationId,'teachers'])
        }
        if(user.role == 'Teacher' || user.role == 'Read-Only') {
          console.log('/organization',user.organizationId,'teacher',user.id);
          
          this.router.navigate(['/organization',user.user.organizationId,'teacher',user.user.id])
        }
    }
}