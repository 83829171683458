<div class="dialog-title">
  <div fxLayout="row" fxLayoutAlign="center center" class="edit-title">
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="95">
      <h2 mat-dialog-title class="dialog-title-text">EDIT</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="5">
      <mat-icon fontSet="fas" (click)="close()" fontIcon="fa-times"></mat-icon>
    </div>
  </div>
</div>

<mat-dialog-content class="dialog-container">

  <div class="dialog-body">
    <form [formGroup]="editUserForm">
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-label fxFlex="25">First Name*</mat-label>
        <mat-form-field fxFlex="60" appearance="outline">
          <input matInput required placeholder="First Name" [(ngModel)]="user.firstName" formControlName="firstName"
            type="text">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-label fxFlex="25">Last Name*</mat-label>
        <mat-form-field fxFlex="60" appearance="outline">
          <input matInput required placeholder="Last Name" [(ngModel)]="user.lastName" formControlName="lastName"
            type="text">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-label fxFlex="25">Email*</mat-label>
        <mat-form-field fxFlex="60" appearance="outline">
          <input matInput required placeholder="Email" [(ngModel)]="user.email" formControlName="email" type="text">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-label fxFlex="25">Position</mat-label>
        <mat-form-field fxFlex="60" appearance="outline">
          <input matInput placeholder="Position" [(ngModel)]="user.position" formControlName="position" type="text">
        </mat-form-field>
      </div>
      <div fxLayout="row" class="notes-container" fxLayoutAlign="center center">
        <mat-label fxFlex="25">Notes</mat-label>
        <mat-form-field fxFlex="60" appearance="outline">
          <textarea formControlName="notes" [(ngModel)]="user.notes" placeholder="Notes" rows="3" matInput></textarea>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>

      <div fxLayout="row" class="picture-profile-container" fxLayoutAlign="center center">
        <mat-label fxFlex="25">Profile Picture</mat-label>
        <div fxFlex="60" fxLayout="column" fxLayoutAlign="center center">
          <div fxLayout="row" class="img-container" fxLayoutAlign="center center">
            <img fxFlex="50" [src]="userProfileImg? userProfileImg : user.img">
          </div>
          <div fxLayout="row" fxLayoutAlign="center center">
            <button (click)="file.click()" mat-raised-button>
              <mat-icon fontSet="fas" fontIcon="fa-upload"></mat-icon>
              Upload Profile Picture
            </button>
            <input style="display: none" #file type="file" name="image" accept='image/*'
              (change)="uploadImg(file.files)" />
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-label fxFlex="25">New Password</mat-label>
        <mat-form-field fxFlex="60" appearance="outline">
          <input matInput placeholder="Password" [(ngModel)]="user.password" formControlName="password" type="password">
        </mat-form-field>
      </div>
    </form>
  </div>

</mat-dialog-content>
<div mat-dialog-actions class="dialog-footer" fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-raised-button class="cancel-button" (click)="close()">Cancel</button>
  <button mat-raised-button class="save-button" [disabled]="!editUserForm.valid" (click)="saveUser()">Save</button>
</div>